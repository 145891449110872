import 'virtual:vite-plugin-sentry/sentry-config';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const { dist, release } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  enabled: import.meta.env.PROD,
  environment: import.meta.env.REALM,
  dist,
  release,
});
