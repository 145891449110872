import { Badge, Caption2, Heading2, Logo, Title2 } from '@meterup/metric';
import QRCodeSVG from 'pretty-qrcode';
import React from 'react';
import { match } from 'ts-pattern';

import { colors, fonts, fontWeights, shadows, styled } from './stitches';

function isNumeric(str: string) {
  return str.match(/^\d+$/) !== null;
}

function isAlpha(str: string) {
  return str.match(/^[a-zA-Z]+$/) !== null;
}

const Box = styled('div');

const NumericChar = styled('span', { color: colors['brand-600'] });

const AlphaChar = styled('span');

const SymbolChar = styled('span', { color: colors['red-600'] });

const PasswordText = styled(Title2, { color: colors['gray-800'], fontFamily: fonts.mono });

const LargePasswordViewer = ({ password }: { password: string }) => (
  <PasswordText>
    {password.split('').map((c, i) =>
      match(c)
        .when(isNumeric, () => (
          // eslint-disable-next-line react/no-array-index-key
          <NumericChar key={i}>{c}</NumericChar>
        ))
        .when(isAlpha, () => (
          // eslint-disable-next-line react/no-array-index-key
          <AlphaChar key={i}>{c}</AlphaChar>
        ))
        .otherwise(() => (
          // eslint-disable-next-line react/no-array-index-key
          <SymbolChar key={i}>{c}</SymbolChar>
        )),
    )}
  </PasswordText>
);

const PageContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(0, 400px) 1fr',
  gridTemplateRows: '2fr min-content 2fr min-content',
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const Content = styled('div', {
  gridColumn: '2 / 2',
  gridRow: '2 / 2',
  backgroundColor: colors.white,
  vStack: 0,
  maxWidth: 400,
  paddingX: 20,
  alignItems: 'stretch',
});

const ContentItem = styled('div', {
  vStack: '$8',
  padding: 20,
  boxShadow: shadows.fenceTopBottomLight,
});

const KeyValues = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
  boxShadow: shadows.fenceAllLight,
  borderRadius: 16,
  overflow: 'hidden',
});

const QRCodeContentItem = styled('div', { vStack: '$20', paddingY: 48 });

const ContentItemKey = styled(Heading2, { color: colors['brand-600'] });

const ContentItemValue = styled(Title2, { color: colors['gray-800'] });

const PoweredByContainer = styled('div', {
  gridColumn: '1 / 4',
  gridRow: '4 / 4',
  paddingTop: 40,
  paddingBottom: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PoweredByContent = styled('div', { hStack: '$4', alignItems: 'center' });

const PoweredByText = styled(Caption2, {
  whiteSpace: 'nowrap',
  color: colors['gray-400'],
  fontWeight: fontWeights.medium,
});

export type AuthenticationType = 'WEP' | 'WPA' | 'nopass';

interface TabletQRCodeProps {
  type: AuthenticationType;
  ssid: string;
  password: string;
  hiddenSSID: boolean;
  includeMeterLogo?: boolean;
  onRefresh?: () => void;
}

const getWiFiQRCodeConnectionString = (
  type: 'WEP' | 'WPA' | 'nopass',
  ssid: string,
  password: string,
  hiddenSSID: boolean,
) =>
  [
    `WIFI:`,
    type !== 'nopass' && `T:${type};`,
    `S:${ssid};`,
    password && type !== 'nopass' && `P:${password};`,
    hiddenSSID && `H:true;`,
  ]
    .filter(Boolean)
    .join('');

export const TabletQRCode = ({
  type,
  ssid,
  password,
  hiddenSSID,
  includeMeterLogo = true,
  onRefresh,
}: TabletQRCodeProps) => (
  <PageContainer onClick={onRefresh}>
    <Content>
      <QRCodeContentItem>
        <Box css={{ padding: '$20', backgroundColor: colors['gray-50'], borderRadius: 24 }}>
          <QRCodeSVG
            value={getWiFiQRCodeConnectionString(type, ssid, password, hiddenSSID)}
            size={240}
            foregroundColor="#1E202E"
          />
        </Box>
        <Badge size="large" icon="phone" arrangement="leading-icon">
          Scan me!
        </Badge>
      </QRCodeContentItem>
      <KeyValues>
        <ContentItem>
          <ContentItemKey>Network</ContentItemKey>
          <ContentItemValue>{ssid}</ContentItemValue>
        </ContentItem>
        {type !== 'nopass' && (
          <ContentItem>
            <ContentItemKey>Password</ContentItemKey>
            <LargePasswordViewer password={password} />
          </ContentItem>
        )}
      </KeyValues>
    </Content>
    {includeMeterLogo && (
      <PoweredByContainer>
        <PoweredByContent as="a" href="https://www.meter.com/" target="_blank">
          <Box>
            <PoweredByText>Powered by</PoweredByText>
          </Box>
          <Logo height={3} />
        </PoweredByContent>
      </PoweredByContainer>
    )}
  </PageContainer>
);
